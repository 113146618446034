import { lazy } from '../../utils/lazy';

import MainLayout from '../../layout/MainLayout';
import Loadable from '../../components/Loadable';
import AdminGuard from '../../utils/route-guard/AdminGuard';
import { useVariableValue } from '@devcycle/devcycle-react-sdk';
import { MaintenanceWindowBanner } from '../../components/maintenance-window-banner';

const HomePage = Loadable(lazy(() => import('../../pages/Home')));

const ProductList = Loadable(lazy(() => import('../../pages/MasterData/Products/products')));

const AddProduct = Loadable(lazy(() => import('../../pages/MasterData/Products/products-form')));

const AddCompany = Loadable(lazy(() => import('../../pages/MasterData/Companies/add-company')));
const CompanyList = Loadable(lazy(() => import('../../pages/MasterData/Companies/companies')));

const ContractsList = Loadable(lazy(() => import('../../pages/ContractsNew/contractsnew')));
const AddContract = Loadable(lazy(() => import('../../pages/ContractsNew/contract-form')));
const ContractDetailsPage = Loadable(lazy(() => import('../../pages/ContractsNew/contract-details')));

const AddBooking = Loadable(lazy(() => import('../../pages/Bookings/add-booking')));
const BookingsList = Loadable(lazy(() => import('../../pages/Bookings/bookings-list')));
const BookingDetailsPage = Loadable(lazy(() => import('../../pages/Bookings/booking-details')));

const SearchSchedule = Loadable(lazy(() => import('../../pages/TransportSchedule/search-schedule')));

const FuturesOrders = Loadable(lazy(() => import('../../pages/Futures-orders/futures-orders-list')));
const AddFuturesOrder = Loadable(lazy(() => import('../../pages/Futures-orders/form')));
const FuturesOrderDetails = Loadable(lazy(() => import('../../pages/Futures-orders/details')));

const ShipmentsList = Loadable(lazy(() => import('../../pages/Shipments/shipments-list')));
const AddShipments = Loadable(lazy(() => import('../../pages/Shipments/add-shipment')));
const ShipmentDetails = Loadable(lazy(() => import('../../pages/Shipments/shipment-details')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/home',
          element: <HomePage />
        },
        {
          path: 'contracts',
          element: (
            <AdminGuard>
              <ContractsList />
            </AdminGuard>
          )
        },
        {
          path: 'contracts/add',
          element: (
            <AdminGuard>
              <AddContract />
            </AdminGuard>
          )
        },
        {
          path: 'contracts/sale/add',
          element: (
            <AdminGuard>
              <AddContract />
            </AdminGuard>
          )
        },
        {
          path: 'contracts/purchase/add',
          element: (
            <AdminGuard>
              <AddContract />
            </AdminGuard>
          )
        },
        {
          path: 'contracts/:id/edit',
          element: (
            <AdminGuard>
              <AddContract />
            </AdminGuard>
          )
        },
        {
          path: 'contracts/:type/:id/:tab',
          element: (
            <AdminGuard>
              <ContractDetailsPage />
            </AdminGuard>
          )
        },
        {
          path: 'master-data',
          children: [
            {
              path: 'companies',
              element: (
                <AdminGuard>
                  <CompanyList />
                </AdminGuard>
              )
            },
            {
              path: 'companies/add',
              element: (
                <AdminGuard>
                  <AddCompany />
                </AdminGuard>
              )
            },
            {
              path: 'companies/:id/edit',
              element: (
                <AdminGuard>
                  <AddCompany />
                </AdminGuard>
              )
            },

            {
              path: 'products',
              element: (
                <AdminGuard>
                  <ProductList />
                </AdminGuard>
              )
            },
            {
              path: 'products/add',
              element: (
                <AdminGuard>
                  <AddProduct />
                </AdminGuard>
              )
            },
            {
              path: 'products/:id/edit',
              element: (
                <AdminGuard>
                  <AddProduct />
                </AdminGuard>
              )
            }
          ]
        },
        {
          path: 'bookings',
          element: (
            <AdminGuard>
              <BookingsList />
            </AdminGuard>
          )
        },
        {
          path: 'bookings/add',
          element: (
            <AdminGuard>
              <AddBooking />
            </AdminGuard>
          )
        },
        {
          path: 'bookings/:id/details',
          element: (
            <AdminGuard>
              <BookingDetailsPage />
            </AdminGuard>
          )
        },
        {
          path: 'bookings/:id/containers',
          element: (
            <AdminGuard>
              <BookingDetailsPage />
            </AdminGuard>
          )
        },
        {
          path: 'bookings/:id/edit',
          element: (
            <AdminGuard>
              <AddBooking />
            </AdminGuard>
          )
        },
        {
          path: 'futures-orders',
          element: (
            <AdminGuard>
              <FuturesOrders />
            </AdminGuard>
          )
        },
        {
          path: 'futures-orders/add',
          element: (
            <AdminGuard>
              <AddFuturesOrder />
            </AdminGuard>
          )
        },
        {
          path: 'futures-orders/:id/:tab',
          element: (
            <AdminGuard>
              <FuturesOrderDetails />
            </AdminGuard>
          )
        },
        {
          path: 'search-schedules',
          element: (
            <AdminGuard>
              <SearchSchedule />
            </AdminGuard>
          )
        },
        {
          path: 'shipments',
          element: (
            <AdminGuard>
              <ShipmentsList />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/add',
          element: (
            <AdminGuard>
              <AddShipments />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/edit',
          element: (
            <AdminGuard>
              <AddShipments />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/details',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/costs',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/containers',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/documents',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/document-instructions',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/loading-instructions',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/booking',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/shipping-instructions',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        },
        {
          path: 'shipments/:id/pdf',
          element: (
            <AdminGuard>
              <ShipmentDetails />
            </AdminGuard>
          )
        }
      ]
    }
  ]
};

export default MainRoutes;
