import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { withDevCycleProvider } from '@devcycle/devcycle-react-sdk';
import ExtractedThemeCustomization from '@myfrey-components/theme';
import Snackbar from './components/@extended/Snackbar';
import ScrollTop from './components/ScrollTop';
import config, { apiBaseUrl, datadogConfig, devCycleSDKKey } from './config';
import { AuthContextProvider } from './contexts/AuthContext/AuthContext';
import { JobProvider } from './contexts/JobContext';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { env } from './env';
import Routes from './navigation/routes';
import { Container as ModalContainer } from 'react-modal-promise';
import useMarkerIo from './hooks/MarkerIo';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

const ThemeComponentFeatureFlagComponent: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  useMarkerIo();

  return (
    <ExtractedThemeCustomization
      fontFamily={config.fontFamily}
      mode={config.mode}
      presetColor={config.presetColor}
      themeDirection={config.themeDirection}
    >
      {children}
    </ExtractedThemeCustomization>
  );
};

const App = () => {
  return (
    <ThemeComponentFeatureFlagComponent>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthContextProvider>
          <NotificationsProvider>
            <JobProvider>
              <QueryClientProvider client={queryClient}>
                <SnackbarProvider>
                  <ModalContainer></ModalContainer>
                  <ScrollTop>
                    <>
                      <Routes />
                      <Snackbar />
                    </>
                  </ScrollTop>
                </SnackbarProvider>
              </QueryClientProvider>
            </JobProvider>
          </NotificationsProvider>
        </AuthContextProvider>
      </LocalizationProvider>
    </ThemeComponentFeatureFlagComponent>
  );
};

if (
  datadogConfig.applicationId &&
  datadogConfig.clientToken &&
  ['development', 'production'].includes(env.REACT_APP_ENVIRONMENT.toLowerCase())
) {
  datadogRum.init({
    /**
     * applicationId, clientToken, env, version - comes from env variables in config
     */
    ...datadogConfig,
    site: 'datadoghq.eu',
    service: 'myfrey',
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    enableExperimentalFeatures: ['feature_flags'],
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: true,
    allowedTracingUrls: [
      {
        match: (url: string) => url.startsWith(apiBaseUrl) || false,
        propagatorTypes: ['tracecontext', 'b3']
      }
    ]
  });

  datadogRum.startSessionReplayRecording();
  datadogLogs.init({
    ...datadogConfig,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'myfrey',
    forwardConsoleLogs: ['warn', 'error']
  });
}

export default withDevCycleProvider({ sdkKey: devCycleSDKKey, options: { deferInitialization: true } })(App);
