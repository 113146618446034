import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProductService from '../../services/products/product.service';

const initialState = [];

export const createProduct = createAsyncThunk('products/create', async (payload) => {
  // Destructure the payload
  const { currentCountry, currentProduct, currentCategory, product } = payload;
  const countryCode = currentCountry?.id ?? null;
  const productTypeId = currentProduct?.id ?? null;
  const productCategoryId = currentCategory?.id ?? null;

  const { productName, productShortName, hsCode } = product;
  // Make the API call to create a product
  const res = ProductService.create({
    countryCode,
    productTypeId,
    productCategoryId,
    productName,
    productShortName,
    hsCode,
    isActive: true
  });
  return res.data;
});

export const updateProduct = createAsyncThunk('products/update', async (payload) => {
  const { id, data } = payload;
  const res = await ProductService.update(id, data);
  return res.data;
});

export const deleteProduct = createAsyncThunk('products/delete', async (id) => {
  const response = await ProductService.deleteProduct(id);
  return response.data;
});

export const retrieveProducts = createAsyncThunk('products/retrieve', async () => {
  const res = await ProductService.getAll();
  return res.data;
});

const productSlice = createSlice({
  name: 'product',
  initialState,
  extraReducers: {
    [createProduct.fulfilled]: (state, action) => {
      state.push(action.payload);
    },

    [retrieveProducts.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateProduct.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const productIndex = state.findIndex((product) => product.id === id);
      state = state[productIndex] = action.payload;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((product) => product.id !== id);
    }
  }
});

const { reducer } = productSlice;
export default reducer;
